import { useMutation } from 'react-query'
import { firePlausibleEvent } from 'src/helpers/plausible'
import { saveEmail } from './api'

export const useEmailService = () => {
  const useSendEmail = () =>
    useMutation(saveEmail, {
      onMutate: (variables: any) => {
        console.log('on mutate:', variables)

        if (!variables.token) {
          console.log('aaaaa')
          throw Error('No token provided')
        }
      },
      onSuccess: (data: any, variables: any) => {
        console.log('on success: variables', variables)
        console.log('on success: data', data)
        const [error] = data

        if (error) {
          throw Error(error)
        }

        firePlausibleEvent('Signup')
      },
      onError: (err: any, variables: any) => {
        console.log('on mutate: variables', variables)
        console.log('on mutate: err', err)

        return err
      },
    })

  return {
    useSendEmail,
  }
}
